import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Landlords: React.FC = () => {
	return (
		<div className="bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
			<div className="max-w-7xl mx-auto">
				<h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
					Landlords
				</h1>
				<div className="bg-white shadow-md rounded-lg px-6 py-8 space-y-12">
					{/* Services Section */}
					<section>
						<h2 className="text-3xl font-semibold mb-6">
							Our Comprehensive Property Management Services
						</h2>
						<div className="space-y-4">
							{/* Service Items */}
							{services.map((service, index) => (
								<Accordion key={index} title={service.title}>
									<p>{service.description}</p>
								</Accordion>
							))}
						</div>
					</section>

					{/* Charges Section */}
					<section>
						<h2 className="text-3xl font-semibold mt-8 mb-6">
							Our Fee Structure
						</h2>
						<div className="overflow-x-auto">
							<table className="min-w-full table-auto">
								<thead>
									<tr>
										<th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
											Description
										</th>
										<th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">
											Fee
										</th>
									</tr>
								</thead>
								<tbody className="text-sm text-gray-600">
									{charges.map((charge, index) => (
										<tr key={index} className="border-b">
											<td className="px-4 py-2">{charge.description}</td>
											<td className="px-4 py-2">{charge.fee}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</section>

					{/* Call to Action */}
					<div className="text-center mt-8">
						<Link
							to="/contact-us"
							className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-blue-700 transition"
						>
							Contact Us to Learn More
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Landlords;

// Data for services and charges
const services = [
	{
		title: 'A comprehensive property appraisal',
		description:
			'We evaluate your property to determine the optimal rental value.',
	},
	{
		title: 'Marketing & advertising of the property',
		description:
			'We market your property via Zoopla, our website, and contract holder databases to reach a wide audience.',
	},
	{
		title: 'Accompanied viewings & weekly viewing feedback',
		description:
			'We conduct accompanied viewings and provide you with weekly feedback on the interest and viewings.',
	},
	{
		title: 'Comprehensive Contract Holder referencing and credit checks',
		description:
			'We thoroughly reference potential Contract Holders, including credit checks, to ensure reliable contract holders.',
	},
	{
		title:
			'All aspects of legal conveyancing in association with setting up the contract',
		description:
			'We handle all legal documentation and procedures required to set up the Occupation Contract.',
	},
	{
		title:
			'Assistance and guidance to ensure that the property meets the legal requirements for rental',
		description:
			'We provide advice and assistance to help you comply with all legal requirements for renting out your property.',
	},
	{
		title: 'Management of the Move In process',
		description:
			'We manage the entire move-in process, including providing the Contract Holder with keys, property information, meter readings, deposit scheme information, and copies of safety certificates.',
	},
	{
		title: 'Arranging a comprehensive, independent photographic inventory',
		description:
			'We arrange for a detailed photographic inventory by an independent professional (the cost of the report is not included—please refer to the fee structure).',
	},
	{
		title: 'Payment of funds by Contract Holder to your designated account',
		description:
			'We ensure the Contract Holder pays funds directly to your designated account and handle contacting all utilities to inform them of the new Contract and meter readings.',
	},
	{
		title: 'Providing you with all relevant paperwork',
		description:
			'We supply you with all necessary documents, including signed Occupation Contract, account statements, invoices, safety certificates, and more.',
	},
	{
		title:
			"Full management of the Contract Holder deposit in Agent's registered DPS Custodial account",
		description:
			'We manage the deposit, including logging funds with the Deposit Protection Scheme, negotiating any deductions at the end of the Contract, and providing evidence for any claims.',
	},
	{
		title: 'Monthly rent collection and statements',
		description:
			"We collect the Contract Holder's rent on a monthly basis, pay funds to your account, and supply you with a monthly statement.",
	},
	{
		title: 'Providing income & expense reports for tax returns',
		description:
			'Upon request, we provide you or your accountant with full income and expense reports for your end-of-year tax return.',
	},
	{
		title: '24/7 property maintenance service',
		description:
			'We offer your Contract Holder a 24/7 property maintenance service, tailored to your instructions to ensure prompt and cost-effective repairs.',
	},
	{
		title: 'Property visits and reports',
		description:
			'We carry out property visits every 16 to 32 weeks, supply you with a report, arrange required maintenance, and manage Contract issues.',
	},
	{
		title: 'Managing Contract Holder queries',
		description:
			'We handle all Contract Holder queries during the Occupation Contract.',
	},
	{
		title: 'Rent reviews and communication',
		description:
			'Upon request, we conduct rent reviews at 12 months and communicate any rent changes to your Contract Holder.',
	},
	{
		title: 'Coordinating Occupation Contract renewals',
		description:
			'We coordinate the renewal of Occupation Contracts to ensure continuity of tenancy.',
	},
	{
		title: 'Administration of ongoing legal and safety checks',
		description:
			'We manage the scheduling and administration of required legal and safety checks to keep your property compliant.',
	},
	{
		title: 'Receipt & management of Contract Holder notice to vacate',
		description:
			'We handle the receipt and management of Contract Holder notices to vacate.',
	},
	{
		title: 'Management of the check-out process and key return',
		description:
			'We manage the check-out process, including key return and final inspections.',
	},
	{
		title: 'Arranging for a full property inspection at Contract end',
		description:
			'We arrange for a comprehensive property inspection at the end of the Contract (the cost of the report is not included—please refer to the fee structure).',
	},
	{
		title:
			'Utility meters read at check-out & managing utility check-out processes',
		description:
			'We read utility meters at check-out and manage utility check-out processes to ensure a smooth transition.',
	},
];

const charges = [
	{
		description: 'Pre-Contract Setup Charge',
		fee: "Minimum £250 + VAT or ¼ of the first month's rent + VAT (whichever greater)",
	},
	{
		description: 'Deposit protection administration charge',
		fee: "£50.00 + VAT (Deducted only from the first month's rent of each new Contract)",
	},
	{
		description: 'Management commission',
		fee: '10% of each monthly rental + VAT',
	},
	{
		description: 'Contract renewal',
		fee: '£40.00 + VAT',
	},
	{
		description: 'Insurance claim handling fee',
		fee: '15% + VAT of the value of the claim (minimum £100.00 + VAT)',
	},
];

// Accordion Component
type AccordionProps = {
	title: string;
	children: React.ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className="border rounded-lg">
			<div className="flex items-center">
				<FaCheckCircle className="text-blue-600 w-5 h-5 ml-4" />
				<button
					className="w-full flex items-center justify-between px-4 py-2 text-left"
					onClick={() => setIsOpen(!isOpen)}
				>
					<div className="flex">
						{/* <FaCheckCircle className="text-blue-600 w-5 h-5 mr-2" /> */}
						<span className="font-medium text-gray-800">{title}</span>
					</div>
				</button>
				<span className="mr-4 font-black text-lg">
					{isOpen ? ' - ' : ' + '}
				</span>
			</div>
			{isOpen && (
				<div className="px-6 py-4 bg-gray-50 text-gray-700">{children}</div>
			)}
		</div>
	);
};
