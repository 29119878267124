import React from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import AboutUs from './pages/AboutUs';
import ComplaintsProcedure from './pages/ComplaintsProcedure';
import ContactUs from './pages/ContactUs';
import ContractHolders from './pages/ContractHolders';
import EqualityAndDiversityPolicy from './pages/EqualityAndDiversityPolicy';
import Home from './pages/Home';
import { default as Landlords } from './pages/Landlord';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App: React.FC = () => {
	return (
		<Router>
			<div className="min-h-screen flex flex-col">
				{/* Navbar */}
				<header className="w-full">
					<NavBar />
				</header>

				{/* Main Content */}
				<main className="flex-grow">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/landlords" element={<Landlords />} />
						<Route path="/contract-holders" element={<ContractHolders />} />
						<Route path="/contact-us" element={<ContactUs />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route
							path="/complaints-procedure"
							element={<ComplaintsProcedure />}
						/>
						<Route
							path="/equality-and-diversity-policy"
							element={<EqualityAndDiversityPolicy />}
						/>
					</Routes>
				</main>

				{/* Footer */}
				<footer className="w-full bg-gray-900 text-white py-4">
					<Footer />
				</footer>
			</div>
		</Router>
	);
};

export default App;
